<template>
  <router-link
    v-bind="attrs"
    class="tw-inline-block tw-no-underline tw-text-inherit"
    :class="{
      'tw-transition-colors tw-duration-200 tw-ease-in-out': type === 'text'
    }"
  >
    <slot></slot>
  </router-link>
</template>

<script>
import { DEFAULT_LOCALE } from '@web/constants/language'

export default {
  props: {
    type: {
      type: String,
      default: 'text',
      validator: function (value) {
        return ['text', 'other'].indexOf(value) !== -1
      }
    }
  },
  computed: {
    appLocale() {
      return this.$store.getters.appLocale
    },
    attrs() {
      return {
        ...this.$attrs,
        to: {
          ...this.$attrs.to,
          params: {
            ...this.$attrs.to.params,
            locale: this.appLocale === DEFAULT_LOCALE ? undefined : this.appLocale
          }
        }
      }
    }
  }
}
</script>
