import { render, staticRenderFns } from "./k-footer.vue?vue&type=template&id=16e3a6fb&"
import script from "./k-footer.vue?vue&type=script&lang=js&"
export * from "./k-footer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KContainer from '@web/components/core/k-container'
import KIcon from '@web/components/core/k-icon'
import KLink from '@web/components/core/k-link'
import KLogo from '@web/components/core/k-logo'
installComponents(component, {KContainer,KIcon,KLink,KLogo})
