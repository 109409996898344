<template>
  <footer class="tw-bg-gray-900 tw-py-24 tw-text-white">
    <k-container>
      <div>
        <k-link to="/">
          <k-logo class="tw-h-10 sm:tw-h-full tw-object-left" color="white" />
        </k-link>
      </div>

      <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-mt-12">
        <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2">
          <div>
            <p class="tw-heading-5">{{ $t('navigation.titles.menu') }}</p>
            <ul class="tw-list-none tw-mt-10 tw-p-0 tw-space-y-4">
              <li>
                <k-link :to="{ name: 'Home' }" class="tw-text-gray-300 hover:tw-text-white">
                  {{ $t('navigation.home') }}
                </k-link>
              </li>
              <li>
                <k-link :to="{ name: 'project-index' }" class="tw-text-gray-300 hover:tw-text-white">
                  {{ $t('navigation.jobs') }}
                </k-link>
              </li>
              <li>
                <k-link
                  :to="{ name: 'project-detail', params: { id: 'sales-trainee' } }"
                  class="tw-text-gray-300 hover:tw-text-white"
                >
                  {{ $t('navigation.carrier') }}
                </k-link>
              </li>
              <li>
                <k-link :to="{ name: 'event-index' }" class="tw-text-gray-300 hover:tw-text-white">
                  {{ $t('navigation.events') }}
                </k-link>
              </li>
              <li>
                <k-link :to="{ name: 'blog-index' }" class="tw-text-gray-300 hover:tw-text-white">
                  {{ $t('navigation.blog') }}
                </k-link>
              </li>
              <li>
                <k-link :to="{ name: 'newsfeed-index' }" class="tw-text-gray-300 hover:tw-text-white">
                  {{ $t('navigation.newsfeed') }}
                </k-link>
              </li>
              <!--                            <li>-->
              <!--                                <k-link :to="{ name: 'dictionary-index' }" class="tw-text-gray-300 hover:tw-text-white">{{-->
              <!--                                    $t("navigation.dictionary")-->
              <!--                                }}</k-link>-->
              <!--                            </li>-->
            </ul>
          </div>
          <div>
            <p class="tw-heading-5 tw-select-none" aria-hidden="true">&#8203;</p>
            <ul class="tw-list-none md:tw-mt-10 tw-p-0 tw-space-y-4">
              <li>
                <k-link :to="{ name: 'AboutUs' }" class="tw-text-gray-300 hover:tw-text-white">
                  {{ $t('navigation.what_is_koderia') }}
                </k-link>
              </li>
              <li>
                <k-link :to="{ name: 'Questionaire' }" class="tw-text-gray-300 hover:tw-text-white">
                  {{ $t('navigation.adequate_salary') }}
                </k-link>
              </li>
              <li>
                <k-link :to="{ name: 'Calculator' }" class="tw-text-gray-300 hover:tw-text-white">
                  {{ $t('navigation.calculator') }}
                </k-link>
              </li>
              <li>
                <k-link :to="{ name: 'WorkComparison' }" class="tw-text-gray-300 hover:tw-text-white">
                  {{ $t('navigation.work_comparison') }}
                </k-link>
              </li>
              <li>
                <k-link :to="{ name: 'referral' }" class="tw-text-gray-300 hover:tw-text-white">
                  {{ $t('navigation.koderia_referral') }}
                </k-link>
              </li>
              <li>
                <k-link :to="{ name: 'extra' }" class="tw-text-gray-300 hover:tw-text-white">
                  {{ $t('navigation.koderia_extra') }}
                </k-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-mt-16 lg:tw-mt-0">
          <div />
          <div class="tw-mt-16 md:tw-mt-0">
            <p class="tw-heading-5">{{ $t('navigation.titles.legal_information') }}</p>
            <ul class="tw-list-none tw-mt-10 tw-p-0 tw-space-y-4">
              <li>
                <k-link :to="{ name: 'TermsAndConditions' }" class="tw-text-gray-300 hover:tw-text-white">
                  {{ $t('navigation.privacy_policy') }}
                </k-link>
              </li>
            </ul>

            <p class="tw-heading-5 tw-mt-12">{{ $t('navigation.titles.social_network') }}</p>
            <div class="tw-flex tw-items-center tw-space-x-2 tw-mt-10">
              <a
                href="https://www.facebook.com/koderiask/"
                target="_blank"
                rel="noopener noreferrer"
                class="tw-text-white hover:tw-text-gray-300"
              >
                <k-icon name="facebook" />
              </a>

              <a
                href="https://www.linkedin.com/company/koderiask/"
                target="_blank"
                rel="noopener noreferrer"
                class="tw-text-white hover:tw-text-gray-300"
              >
                <k-icon name="linkedin" />
              </a>

              <a
                href="https://www.instagram.com/koderiask/"
                target="_blank"
                rel="noopener noreferrer"
                class="tw-text-white hover:tw-text-gray-300"
              >
                <k-icon name="instagram" />
              </a>

              <a
                href="https://open.spotify.com/playlist/09Zy7jEChx3noVIT13huH9"
                target="_blank"
                rel="noopener noreferrer"
                class="tw-text-white hover:tw-text-gray-300"
              >
                <k-icon name="spotify" />
              </a>
            </div>

            <p class="tw-mt-12">Copyright &copy; {{ new Date().getFullYear() }} Objectify s.r.o.</p>
          </div>
        </div>
      </div>
    </k-container>
  </footer>
</template>

<script>
import { getRouteLocale } from '@web/constants/language'

export default {
  computed: {
    locale() {
      return getRouteLocale()
    }
  }
}
</script>
