<template>
  <component :is="as" class="tw-px-4 md:tw-px-10">
    <div class="tw-mx-auto tw-w-full tw-max-w-screen-xl">
      <div :class="slotsWrapperClass" class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-space-y-0 lg:tw-space-x-8">
        <div class="tw-w-full tw-min-w-0">
          <slot></slot>
        </div>
        <div v-if="hasSlot('aside') && !(isMobile && hideAsideMobile)" class="tw-w-full aside">
          <div
            :class="{ 'tw-h-full': disableAsideSticky }"
            v-sticky="{ zIndex: 1, stickyTop: 110 + stickyTop, disabled: disableAsideSticky }"
          >
            <div class="tw-h-full">
              <slot name="aside"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import Sticky from 'vue-sticky'
import responsiveMixin from './../../responsiveMixin'

export default {
  directives: { Sticky },
  mixins: [responsiveMixin],
  props: {
    as: {
      type: String,
      default: 'div'
    },
    hideAsideMobile: Boolean,
    disableAsideSticky: {
      type: Boolean,
      default: false
    },
    stickyTop: {
      type: Number,
      default: 0
    },
    slotsWrapperClass: {
      type: String,
      default: 'tw-space-y-8'
    }
  },
  methods: {
    hasSlot(slotName = 'default') {
      return !!this.$slots[slotName] || !!this.$scopedSlots[slotName]
    }
  }
}
</script>

<style lang="scss" scoped>
.aside {
  @include lg {
    max-width: 31%;
  }
}
</style>
