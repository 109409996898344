<template>
  <div>
    <v-navigation-drawer
      v-if="hasUserProfile"
      :value="userDrawer"
      @input="v => !v && $emit('close-user-drawer')"
      :right="true"
      app
      floating
      temporary
      :width="424"
      class="tw-z-20"
    >
      <div class="tw-py-4 tw-px-6">
        <div class="tw-flex tw-items-center tw-justify-between">
          <h5 class="tw-heading-5">{{ $t('auth.titles.account') }}</h5>
          <v-btn icon @click="closeUserDrawer" class="tw-text-black">
            <k-icon name="close" />
          </v-btn>
        </div>

        <div class="tw-mt-6 tw-flex tw-flex-col tw-space-y-4">
          <!--
          <router-link :to="{ name: 'profile', params: { locale: routeLocale } }" class="tw-py-0.5 tw-text-base tw-text-black tw-no-underline focus:tw-outline-none">
              <span class="tw-inline-flex tw-flex-col">
                  <span class="tw-text-base"> {{ profile.firstName }} {{ profile.lastName }} </span>
                  <span v-if="profile.profession" class="tw-text-sm tw-text-gray-900">
                      {{ profile.profession }}
                  </span>
                  <span v-else class="tw-text-sm tw-text-koderia">
                      {{ $t('profile.actions.fill_position') }}
                  </span>
              </span>
          </router-link>

          <div class="tw-max-w-full tw-h-px tw-bg-gray-300">
               Divider
          </div>
          -->

          <!--                    <router-link-->
          <!--                        :to="{ name: 'profile-saved', params: { locale: routeLocale } }"-->
          <!--                        class="tw-py-0.5 tw-text-base tw-text-black tw-no-underline focus:tw-outline-none hover:tw-underline focus:tw-underline"-->
          <!--                    >-->
          <!--                        <span class="tw-inline-flex tw-items-center">-->
          <!--                            <k-icon name="heart_border" />-->
          <!--                            <span class="tw-ml-2">{{ $t("global.titles.saved") }}</span>-->
          <!--                        </span>-->
          <!--                    </router-link>-->

          <!--                    <div class="tw-max-w-full tw-h-px tw-bg-gray-300">-->
          <!--                        &lt;!&ndash; Divider &ndash;&gt;-->
          <!--                    </div>-->

          <router-link
            :to="{ name: 'profile', params: { locale: routeLocale } }"
            class="tw-py-0.5 tw-text-base tw-text-black tw-no-underline focus:tw-outline-none hover:tw-underline focus:tw-underline"
          >
            {{ $t('navigation.settings') }}
          </router-link>

          <a
            @click="$store.dispatch('AUTH/signOut')"
            class="tw-py-0.5 tw-text-base tw-text-black tw-no-underline focus:tw-outline-none hover:tw-underline focus:tw-underline"
          >
            {{ $t('auth.actions.logout') }}
          </a>
        </div>
      </div>
    </v-navigation-drawer>

    <v-navigation-drawer
      :value="menuDrawer"
      @input="v => !v && $emit('close-menu-drawer')"
      :right="true"
      disable-route-watcher
      app
      floating
      temporary
      :width="424"
      class="tw-z-20"
    >
      <div class="tw-flex tw-flex-col tw-py-4 tw-px-6 tw-h-full tw-overflow-y-auto">
        <div class="tw-flex tw-items-center tw-justify-between">
          <h5 class="tw-heading-5">{{ $t('navigation.titles.menu') }}</h5>
          <v-btn icon @click="closeDrawer" class="tw-text-black">
            <k-icon name="close" />
          </v-btn>
        </div>

        <k-nav-user style="isolation: isolate" @open-drawer="$emit('open-drawer')" class="tw-self-stretch" />

        <div class="tw-mt-2 tw-flex tw-flex-col tw-space-y-4">
          <div class="tw-max-w-full tw-h-px tw-bg-gray-300">
            <!-- Divider -->
          </div>

          <router-link
            :to="{ name: 'Home', params: { locale: routeLocale } }"
            class="tw-py-0.5 tw-text-base tw-text-black tw-no-underline focus:tw-outline-none hover:tw-underline focus:tw-underline"
            @click="closeDrawer"
          >
            {{ $t('navigation.home') }}
          </router-link>

          <router-link
            :to="{ name: 'project-index', params: { locale: routeLocale } }"
            class="tw-py-0.5 tw-text-base tw-text-black tw-no-underline focus:tw-outline-none hover:tw-underline focus:tw-underline"
            @click="closeDrawer"
          >
            {{ $t('navigation.jobs') }}
          </router-link>

          <router-link
            v-if="!routeLocale"
            :to="{ name: 'event-index', params: { locale: routeLocale } }"
            class="tw-py-0.5 tw-text-base tw-text-black tw-no-underline focus:tw-outline-none hover:tw-underline focus:tw-underline"
            @click="closeDrawer"
          >
            {{ $t('navigation.events') }}
          </router-link>

          <router-link
            :to="{ name: 'blog-index', params: { locale: routeLocale } }"
            class="tw-py-0.5 tw-text-base tw-text-black tw-no-underline focus:tw-outline-none hover:tw-underline focus:tw-underline"
            @click="closeDrawer"
          >
            {{ $t('navigation.blog') }}
          </router-link>

          <router-link
            :to="{ name: 'newsfeed-index', params: { locale: routeLocale } }"
            class="tw-py-0.5 tw-text-base tw-text-black tw-no-underline focus:tw-outline-none hover:tw-underline focus:tw-underline"
            @click="closeDrawer"
          >
            {{ $t('navigation.newsfeed') }}
          </router-link>

          <router-link
            v-if="!routeLocale"
            :to="{ name: 'dictionary-index', params: { locale: routeLocale } }"
            class="tw-py-0.5 tw-text-base tw-text-black tw-no-underline focus:tw-outline-none hover:tw-underline focus:tw-underline"
            @click="closeDrawer"
          >
            {{ $t('navigation.dictionary') }}
          </router-link>

          <router-link
            :to="cvTo"
            class="tw-py-0.5 tw-text-base tw-text-black tw-no-underline focus:tw-outline-none hover:tw-underline focus:tw-underline"
            @click="closeDrawer"
          >
            {{ $t('global.my_resume') }}
          </router-link>

          <div v-if="!routeLocale" class="tw-max-w-full tw-h-px tw-bg-gray-300">
            <!-- Divider -->
          </div>

          <!--
          <router-link
              :to="{ name: 'Questionaire', params: { locale: routeLocale } }"
              class="tw-py-0.5 tw-text-base tw-text-black tw-no-underline focus:tw-outline-none hover:tw-underline focus:tw-underline"
              @click="closeDrawer"
          >
              {{ $t('navigation.adequate_salary') }}
          </router-link>

          <router-link
              v-if="!routeLocale"
              :to="{ name: 'Calculator', params: { locale: routeLocale } }"
              class="tw-py-0.5 tw-text-base tw-text-black tw-no-underline focus:tw-outline-none hover:tw-underline focus:tw-underline"
              @click="closeDrawer"
          >
              {{ $t('navigation.calculator') }}
          </router-link>
          -->

          <router-link
            v-if="!routeLocale"
            :to="{ name: 'WorkComparison', params: { locale: routeLocale } }"
            class="tw-py-0.5 tw-text-base tw-text-black tw-no-underline focus:tw-outline-none hover:tw-underline focus:tw-underline"
            @click="closeDrawer"
          >
            {{ $t('navigation.work_types_comparison') }}
          </router-link>

          <div class="tw-max-w-full tw-h-px tw-bg-gray-300">
            <!-- Divider -->
          </div>

          <router-link
            :to="{ name: 'extra', params: { locale: routeLocale } }"
            class="tw-py-0.5 tw-text-base tw-text-black tw-no-underline focus:tw-outline-none hover:tw-underline focus:tw-underline"
            @click="closeDrawer"
          >
            {{ $t('navigation.koderia_extra') }}
          </router-link>

          <router-link
            :to="{ name: 'referral', params: { locale: routeLocale } }"
            class="tw-py-0.5 tw-text-base tw-text-black tw-no-underline focus:tw-outline-none hover:tw-underline focus:tw-underline"
            @click="closeDrawer"
          >
            Koderia <span class="tw-text-koderia">Referral</span>
          </router-link>

          <router-link
            v-if="!routeLocale"
            :to="{ name: 'AboutUs', params: { locale: routeLocale } }"
            class="tw-py-0.5 tw-text-base tw-text-black tw-no-underline focus:tw-outline-none hover:tw-underline focus:tw-underline"
            @click="closeDrawer"
          >
            {{ $t('navigation.about_us') }}
          </router-link>

          <router-link
            v-if="!routeLocale"
            :to="{ name: 'AboutUs', hash: '#contact', params: { locale: routeLocale } }"
            class="tw-py-0.5 tw-text-base tw-text-black tw-no-underline focus:tw-outline-none hover:tw-underline focus:tw-underline"
            @click="closeDrawer"
          >
            {{ $t('navigation.contact_us') }}
          </router-link>

          <router-link
            v-if="!routeLocale"
            :to="{ name: 'KoderiaAbout', params: { locale: routeLocale } }"
            class="tw-py-0.5 tw-text-base tw-text-black tw-no-underline focus:tw-outline-none hover:tw-underline focus:tw-underline"
            @click="closeDrawer"
          >
            {{ $t('navigation.what_is_koderia') }}
          </router-link>

          <div class="tw-max-w-full tw-h-px tw-bg-gray-300">
            <!-- Divider -->
          </div>

          <router-link
            :to="{ name: 'TermsAndConditions', params: { locale: routeLocale } }"
            class="tw-py-0.5 tw-text-base tw-text-black tw-no-underline focus:tw-outline-none hover:tw-underline focus:tw-underline"
            @click="closeDrawer"
          >
            {{ $t('navigation.privacy_policy') }}
          </router-link>

          <div class="tw-max-w-full tw-h-px tw-bg-gray-300">
            <!-- Divider -->
          </div>

          <div class="tw-flex tw-items-center tw-space-x-2 tw--ml-2">
            <a
              href="https://www.facebook.com/koderiask/"
              target="_blank"
              rel="noopener noreferrer"
              class="tw-text-black hover:tw-text-gray-900 tw-p-2"
            >
              <k-icon name="facebook" />
            </a>

            <a
              href="https://www.linkedin.com/company/koderiask/"
              target="_blank"
              rel="noopener noreferrer"
              class="tw-text-black hover:tw-text-gray-900 tw-p-2"
            >
              <k-icon name="linkedin" />
            </a>

            <a
              href="https://www.instagram.com/koderiask/"
              target="_blank"
              rel="noopener noreferrer"
              class="tw-text-black hover:tw-text-gray-900 tw-p-2"
            >
              <k-icon name="instagram" />
            </a>

            <a
              href="https://open.spotify.com/playlist/09Zy7jEChx3noVIT13huH9"
              target="_blank"
              rel="noopener noreferrer"
              class="tw-text-black hover:tw-text-gray-900 tw-p-2"
            >
              <k-icon name="spotify" />
            </a>
          </div>
        </div>

        <div v-if="!hasUserProfile" class="tw-mt-4 md:tw-mt-auto tw-space-y-4 tw-flex tw-flex-col">
          <div class="tw-max-w-full tw-h-px tw-bg-gray-300 md:tw-hidden">
            <!-- Divider -->
          </div>

          <k-language-nav-switcher />
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { getRouteLocale } from '@web/constants/language'
import KLanguageNavSwitcher from '@web/components/core/k-language-nav-switcher'
import { mapGetters } from 'vuex'

export default {
  components: { KLanguageNavSwitcher },
  props: ['menuDrawer', 'userDrawer'],

  computed: {
    ...mapGetters('USER', {
      hasUserProfile: 'getHasUserProfile'
    }),
    routeLocale() {
      return getRouteLocale()
    },
    cvTo() {
      return { name: 'profile-cv', params: { locale: this.routeLocale } }
    }
  },
  methods: {
    closeDrawer() {
      this.$emit('close-menu-drawer')
    },
    closeUserDrawer() {
      this.$emit('close-user-drawer')
    }
  }
}
</script>

<style></style>
